import { graphql } from "gatsby"
import React,{useState, useEffect} from "react"
import Layout from "../components/layout"
// import NewsListing from "../components/NewsListing/NewsListing"
import NewsStaticCard from "../components/NewsStaticCard/NewsStaticCard"
import NewsListing1 from "../components/NewsListing/NewsListing1"
import SEO from "../components/seo"
import qs from "qs"
import logoBlack from "../images/logo-white.svg"
import { removeDataAttributes } from "../components/Common/utils"


const NewsLandingTemplate = ({ data,pageContext }) => {
  const allNews = data?.allStrapiBlog.edges
  const id = data?.strapiPage.strapi_id;

  const [PageData, setPageData] = useState(data.strapiPage)
  const [loading,setLoading] = useState(true)
  const [isPreviewEnabled, setIsPreviewEnabled]=useState(false)

  const [enablePreview, setEnablePreview] = useState(pageContext?.strapi_preview == "true" ? true : false)

  

  const blogCategories=data?.allStrapiBlogCategorie?.edges;
  const blogTopics=data?.allStrapiBlogTopic?.edges;

  const subscriptionData=data?.allStrapiSiteConfig?.edges[0];


  // const PageData=data.strapiPage

 

  let popUpData=""

  if(PageData?.add_page_modules?.length>0){
     const results=PageData?.add_page_modules?.filter(item=>item.__typename==="STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT")
     if(results?.length>0){
      popUpData=results[0]
     }
  }

  const query = qs.stringify(
    {
      populate: {
        ggfx_results:"*",
        choose_menu: "*",
        select_popular_search:"*",
        
        add_page_modules: {
          on: {
            "components.title-desc":{
              populate:"*"
            },
            "page-modules.global-module":{
              populate:{
                select_module:"*"
              }
            },
            "components.blog-relation":{
              populate:{
                select_blog:{
                  populate:{
                    banner_image:"*"
                  }
                }
              }
            },
            "page-modules.static-card-items":{
              populate:{
                cards:{
                  populate:{
                    cta:{
                      populate:{
                        link: {
                          populate:{
                            parent:"*"
                          }
                        },
                        image:"*"
                      }
                    }
                  }
             }

          }
        }
          },
        },
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const isPreview = urlParams.get('debug') === 'preview';
    const apiUrl=`${process.env.GATSBY_STRAPI_SRC}/api/pages/${urlParams.get('strapi_id')}?${query}`

    // const apiUrl=process.env.GATSBY_STRAPI_SRC+'/api/events/'+urlParams.get('strapi_id')+'?populate[0]=modules&populate[1]=banner.cta_1_link&populate[2]=banner.cta_2_link&populate[3]=modules.icon_content&populate[4]=modules.icon_content.icon_module.image&populate[5]=form_content&populate[6]=ggfx_results&populate[12]=banner.image'
    const bearerToken = process.env.GATSBY_STRAPI_API_TOKEN;

    if(isPreview){
    setIsPreviewEnabled(true)
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        const getData = await response.json();
        const previewData=removeDataAttributes(getData)
        setLoading(false)
        setEnablePreview(false)

        let myPrevData;
        if(PageData){
          myPrevData = PageData
        } else {
          myPrevData = {};
          myPrevData.add_page_modules=[]
          myPrevData.id = urlParams.get('strapi_id')
        }

        if(previewData){
          
          myPrevData.imagetransforms = previewData?.imagetransforms
          myPrevData.title = previewData?.title
          myPrevData.layout = previewData?.layout
          myPrevData.select_popular_search = previewData.select_popular_search
          myPrevData.choose_menu = previewData?.choose_menu

          myPrevData.ggfx_results = previewData?.ggfx_results
          myPrevData.add_page_modules=previewData?.add_page_modules
          setPageData(PageData => ({ ...PageData, myPrevData }));
        }
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };
    fetchData();
    }
    else{
      setLoading(false)
      setIsPreviewEnabled(false)
    }

  },[])

  let urlParamsVal = ""
  let isPreviewVal = false

  if(typeof window !== "undefined"){
     urlParamsVal = new URLSearchParams(window.location.search);
    isPreviewVal = urlParamsVal.get('debug') === 'preview';
  }

  if(isPreviewVal){
    if(loading){
      return (
        <section className={"loader-wrapper"}>
          <div id="loader-wrapper">
            <div id="loader" class="new-loader">
              <img
                className="logo-white loader-logo"
                src={logoBlack}
                alt="logo"
              />
            </div>
          </div>
        </section>
      )
    }
  }

  if(enablePreview){
    return null
  }

  const getBlogData=PageData.add_page_modules?.filter((item,i)=>{
    if(item?.__typename ===
      "STRAPI__COMPONENT_COMPONENTS_BLOG_RELATION" || 
      item?.__component === "components.blog-relation"
    ){
        return item.select_blog
      }
  })


  const getBlog=getBlogData?.length>0&&getBlogData[0]?.select_blog;

  let todayDate = new Date();
  const sortedBlog = allNews.filter(item=>{
    const itemDate = new Date(item.node.date);
    return itemDate <= todayDate
  })

  return (
    <Layout popUpData={popUpData} dark={true} popularSearch={PageData?.select_popular_search?.title}>
      <div className="layout-padding-top"></div>
      <div className="news-landing-template">
        {/*  */}
        
        {PageData.add_page_modules?.map((item, i) => {
        return (
          <div>
             {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" ||
              item?.__component === "page-modules.global-module")
              && item?.select_module==="blog"&& (
              <NewsListing1
              data={sortedBlog} 
              strapi_id={id}
              subscriptionData={subscriptionData}
              blogCategories={blogCategories}
              blogTopics={blogTopics}
              newsData={getBlog}
              isPreviewEnabled={isPreviewEnabled}
              />
            )}
            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_STATIC_CARD_ITEMS" ||
              item?.__component === "page-modules.static-card-items")
              && (
              <NewsStaticCard isPreviewEnabled={isPreviewEnabled} cardData={item.cards} />
            )}
          </div>
        )
      })}
      </div>
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  return (
      <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} 
      imageUrl="https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i/file_7035ecdafe.jpg?updated_at=2024-07-08T11:28:34.283Z"

      />
  )
}

export default NewsLandingTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {

        ... on STRAPI__COMPONENT_COMPONENTS_BLOG_RELATION {
          __typename
          id
          select_blog {
            ...BlogFragment
          }
        }

        ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
          __typename
          ...GlobalPopUpFragment
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          id
          __typename
          select_module
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_STATIC_CARD_ITEMS {
          __typename
          id
          cards {
            title
            content {
              data {
                content
              }
            }
            cta {
              title
              custom_link
              link_type
              link {
                slug
                strapi_parent {
                  slug
                }
              }
            }
          }
        }
      }

    }


    allStrapiSiteConfig {
      edges {
        node {
          subscription_card {
            cta {
              link {
                slug
              }
              custom_link
              content {
                data {
                  content
                }
              }
              link_type
              title
            }
            content {
              data {
                content
              }
            }
            title
          }

          podcast_data {
            cta {
              link {
                slug
              }
              custom_link
              content {
                data {
                  content
                }
              }
              link_type
              title
            }
            content {
              data {
                content
              }
            }
            title
            image {
              url
            }
          }
        }
      }
    }

    allStrapiBlogCategorie(sort: { fields: sort, order: ASC }) {
      edges {
        node {
          slug
          blog_category
        }
      }
    }

    allStrapiBlogTopic {
      edges {
        node {
          slug
          topic_name
        }
      }
    }

    allStrapiBlog(
      filter: { publish: { eq: true } }
      sort: { fields: date, order: ASC }
    ) {
      edges {
        node {
          title
          slug
          strapi_id
          read_time
          content {
            data {
              content
            }
          }
          video_url
          date
          tile_image {
            url
          }
          banner_image {
            url
          }
          
          imagetransforms {
            tile_image_Transforms
          }
         
          blog_categories {
            blog_category
            slug
          }
          blog_topics {
            topic_name
            slug
          }
        }
      }
    }
  }
`
